import { scrollFadeInV3, scrollFadeIn } from '@/modules/comm';
import { addRequestPrefix } from '@/shared/util';
import { ing } from '@/shared/tips';
import { postUserComment } from '@/modules/shared/comment';
import { initWebPage } from '@/shared/misc/init-webpage';
import { setCommImgBindShow } from '@/modules/shared/product/comment';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { switchJump } from '@/shared/linkSetting';
import { updateProductDetailSwipe } from './swipe';
import { productCrossedSlideSec, productCrossedSlideSecSwipe } from '@/modules/shared/product/renderStyle';
import { checkWindowSeStgValue } from '@/modules/shared/product/storage';
import { preprocessUrl } from '@/shared/url';
import { deviceTypeIsMobi } from '@/features/global-mobi-function/methods/linkSetting';
import { mallBuy } from '@/shared/mall';
import { createMallServicePanel } from './btn';
import { logDog, logMallFdp, logMonitor } from '@/shared/log';
import { hidePlaceholder } from '@/shared/misc/placeholder';
import { initProductSwipe } from '@/modules/shared/product/swipe';
import { sharePage } from '@/features/global-mobi-function/methods/share';
import { cutPrice } from '@/shared/mall';
import { customPopup, renderDelMsgConfirm } from '@/shared/popup';
import { changeHref, getRouterHistory, goBack } from '@/features/router/universal';
import { mobiIng } from '@/shared/fai';

export function mallProductDetailFixedBar() {
    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000) {
        jm('#fixedAreaBox').find('.productMallShop').remove();
        jm('.productMallShop').appendTo(jm('#fixedAreaBox'));

        jm('.productNewDetailPanel .productDetailTabPanel .tabBody').css('padding-bottom', '2rem'); //购买按钮迁移到fixedAreaBox，原有内容会被遮挡，修复

        //服务
        jm('#serviceBtnOuter').show();
    } else if (VITE_APP_MODE !== 'visitor' && jm('#serviceBtnOuter').length > 0) {
        //非商城主题下管理态下，把客服按钮加至fixAreaBox,用于修复炫彩主题和商城主题之前切换导致的问题
        jm('#fixedAreaBox').find('.productMallShop').remove();
        jm('#serviceBtnOuter').appendTo(jm('#fixedAreaBox'));
        jm('#serviceBtnOuter').css('display', 'block');
    }
}

export function fixPorductDetailStyle(moduleId) {
    var productDetail = jm('#productNewDetailPanel' + moduleId),
        placeHolder = productDetail.find('.J_pricePlaceHolder'),
        mallThemeProductWrap = productDetail.find('.mallThemeProductWrap'),
        hideWrap = mallThemeProductWrap.css('display') == 'none';

    if (placeHolder.length > 0 && hideWrap) {
        placeHolder.addClass('fk-pricePlaceHolder');
    }
}

export function initCustab(moduleId) {
    if (!(VITE_APP_MODE !== 'visitor')) {
        return;
    }
    var module = jm('#module' + moduleId);
    let buttonWrap = module.find('.tabUiDiv');
    if (buttonWrap.find('li').length <= 3) {
        return;
    }

    jm('#productTabUl').css('width', '100%');
    jm('#productTabUl').css('padding', '0');

    // PC端出现左右移动按钮
    jm(
        "<div class='J_preBtn f-preBtn faisco-icons'></div><div class='J_nextBtn f-nextBtn faisco-icons'></div>"
    ).appendTo(buttonWrap);

    buttonWrap.find('.J_preBtn').on('click', function () {
        const left = jm('.tabUiDiv2').offset().left - jm('#preview').offset().left;
        if (left >= 0) {
            return false;
        }
        var py = left + 80 + 'px';
        jm('.tabUiDiv2').css('margin-left', py);
    });

    buttonWrap.find('.J_nextBtn').on('click', function () {
        const left = jm('.tabUiDiv2').offset().left - jm('#preview').offset().left;
        var w = jm('.tabUiDiv').width();

        var w2 = jm('.tabUiDiv2').width();
        if (left < -(w2 - w)) {
            return false;
        }

        var py = left - 80 + 'px';
        jm('.tabUiDiv2').css('margin-left', py);
    });
}

if (typeof Mobi === 'undefined' && typeof window !== 'undefined') {
    window.Mobi = {};
    Mobi.isPtBtn = false; //是否点击“我要开团”按钮
    Mobi.isImBtn = false; //是否点击“立即兑换”按钮
    Mobi.isMarketingBtn = false; //是否点击营销活动的按钮
    Mobi.productNewDetailIScrollObj = null; //存放购买弹层那里的iscroll对象
}

export function productNewDetailEventInit(options) {
    if (!options) return;
    var moduleId = options.moduleId,
        productId = options.productId,
        mallOptionsOpen = options.mallOptionsOpen,
        pageStyle = options.pageStyle,
        returnUrl = options.returnUrl || '',
        isGroupBuy = options.isGroupBuy || false,
        groupBuyId = options.groupBuyId || 0,
        groupBuyDetailId = options.groupBuyDetailId || 0,
        groupPriceStr = options.groupPriceStr || '',
        isCutPrice = options.isCutPrice || false,
        cutPriceId = options.cutPriceId || 0,
        cpPriceStr = options.cpPriceStr || '',
        isSecKill = options.isSecKill || false,
        secKillId = options.secKillId || 0,
        isPtLimitBuy = options.isPtLimitBuy || 0,
        isIntegralMall = options.isIntegralMall || false,
        integralMallId = options.integralMallId || 0,
        integtalMallType = options.integtalMallType || 0,
        imMinPrice = options.imMinPrice || {},
        isImLimitBuy = options.isImLimitBuy || 0,
        isEarnestSale = options.isEarnestSale || false,
        earnestSaleId = options.earnestSaleId || 0,
        pd_optionsAmountInfo = options.pd_optionsAmountInfo || {},
        pd_showOptionList = options.pd_showOptionList || [],
        mkt_optionsAmountInfo = options.mkt_optionsAmountInfo || {},
        mkt_showOptionList = options.mkt_showOptionList || [],
        openPriceCalendar = options.openPriceCalendar,
        themeV3 = options.themeV3 || false,
        isUseSwipe = options.isUseSwipe,
        isHotelProduct = options.isHotelProduct || false;
    if (typeof isUseSwipe === 'undefined') {
        isUseSwipe = true;
    }
    window.isGroupBuy = isGroupBuy;
    var initGroupBuyDetailId = groupBuyDetailId;
    if (themeV3) {
        scrollFadeInV3('productDetailHeader' + moduleId, 'productNewDetailSwipe' + moduleId, pageStyle);
    } else {
        scrollFadeIn('productDetailHeader' + moduleId, 'productNewDetailSwipe' + moduleId, pageStyle);
    }
    initAjaxTab({ pid: productId, moduleId, themeV3, isUseSwipe });

    var headDom = document.getElementById('productDetailHeader' + moduleId),
        productNewDetailPanelDom = document.getElementById('productNewDetailPanel' + moduleId),
        prodcutGoBack = headDom.querySelector('.icon-gClose.proBack'),
        prodcutOther = headDom.querySelector('.icon-gOther.proOther'),
        proOtherPanel = headDom.querySelector('.proOtherPanel'),
        productImmediatelyBug = productNewDetailPanelDom.querySelector('.J_productImmediatelyBug'),
        groupBuyBtn = productNewDetailPanelDom.querySelector('.J_groupBuyBtn'),
        cutPriceBtn = productNewDetailPanelDom.querySelector('.J_cpButton'),
        integralMallBtn = productNewDetailPanelDom.querySelector('.J_integralMallBtn'),
        earnestSaleBtn = productNewDetailPanelDom.querySelector('.J_earnestSaleBtn'),
        productNewDetailSwipe = document.getElementById('productNewDetailSwipe' + moduleId),
        productDetailV3_title = jm('#productDetailV3_title' + moduleId);

    var _isJumpFromMlist = false;
    if (window.sessionStorage) {
        _isJumpFromMlist = checkWindowSeStgValue(window.sessionStorage._isJumpFromMlist);
        try {
            //修复在无痕模式下导致的无法插入sessionStorage产生的问题
            window.sessionStorage._isJumpFromMlist = false;
            // eslint-disable-next-line no-empty
        } catch (result) {}
    }
    var _isJumpFromCollect = false;
    _isJumpFromCollect = jm.getCookie('_fromCollect');
    jm.cookie('_fromCollect', '', { expires: -1, path: '/' });

    const histories = getRouterHistory();

    jm(prodcutGoBack).bind('click', function () {
        if (window.sessionStorage) {
            // 这里判断是不是从订单评论跳过来的，如果是的话，还原订单评论的状态
            if (_isJumpFromMlist) {
                if (window.sessionStorage._jumpMlistUrl.indexOf('?') <= -1) {
                    changeHref(window.sessionStorage._jumpMlistUrl + '?commHideFlag=true');
                } else {
                    if (window.sessionStorage._jumpMlistUrl.indexOf('commHideFlag') <= -1) {
                        changeHref(window.sessionStorage._jumpMlistUrl + '&commHideFlag=true');
                    } else {
                        changeHref(window.sessionStorage._jumpMlistUrl);
                    }
                }
                window.sessionStorage._backFromProDetail = true;
            } else if (_isJumpFromCollect) {
                //返回时打开收藏页面
                jm.cookie('_openCollect', true, { path: '/' });
                changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'profile.jsp');
            } else {
                // 如果是直接扫二维码到详情页,hostory.length为1，左上方的后退没用，所以默认调到首页
                if (histories.length <= 1) {
                    changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                } else {
                    if (returnUrl.length > 0) {
                        changeHref(returnUrl);
                    } else {
                        if (VITE_APP_MODE !== 'visitor') {
                            if (histories.length > 0) {
                                goBack();
                            } else {
                                changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                            }
                        } else {
                            var userAgentStr = navigator.userAgent.toLocaleLowerCase();
                            if (userAgentStr.includes('ucbrowser') && history.length <= 2) {
                                // 修复UC浏览器直接进入文章详情后点击返回键时，页面没有返回首页
                                changeHref('/');
                                return;
                            }

                            goBack();
                        }
                    }
                }
            }
            return false;
        } else {
            if (histories.length <= 1) {
                changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
            } else {
                changeHref(document.referrer);
                // 为了防止返回无效
                setTimeout(function () {
                    changeHref(document.referrer);
                    setTimeout(function () {
                        changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                    }, 500);
                }, 300);
            }
            return false;
        }
    });

    jm(productNewDetailPanelDom).bind('click', function () {
        if (jm(proOtherPanel).hasClass('proOtherPanelOpen')) {
            jm(proOtherPanel).removeClass('proOtherPanelOpen');
        }
    });
    jm(prodcutOther).bind('click', function (e) {
        if (jm(proOtherPanel).hasClass('proOtherPanelOpen')) {
            jm(proOtherPanel).removeClass('proOtherPanelOpen');
        } else {
            jm(proOtherPanel).addClass('proOtherPanelOpen');
        }
        e.stopPropagation();
    });
    if (productDetailV3_title) {
        //适配苹果手机，苹果手机的scroll事件是不能实时监听的，所有要添加多一个touchmove事件,但是目前还是存在一定的问题
        document.addEventListener('touchmove', change);
        //监听滚动事件
        document.addEventListener('scroll', change);
        // eslint-disable-next-line no-inner-declarations
        function change() {
            if (
                document.body.scrollTop > productNewDetailSwipe.clientHeight ||
                document.documentElement.scrollTop > productNewDetailSwipe.clientHeight
            ) {
                productDetailV3_title.show();
            } else {
                productDetailV3_title.hide();
            }
        }
    }

    //---------------
    var btnHtml = jm('.productMallShop').html(),
        pmbpHtml = jm('.productMallBuyPanel').html(),
        productInfoWebPage = initWebPage({
            triggerId: 'productInfoSize',
            panelId: 'productMallOptionPanel',
            callback: productInfo,
            pageBg: true,
            returnSeltFun: true,
            direction: 'bottom',
            closeWebPage: '#productMallOptionPanel .productDetailClose.icon-Off',
            closeCallBack() {
                jm('.productMallOptionPanel .productMallOptionContentPanel').removeClass(
                    'mallThemeProductContentPanel'
                );
                if (Fai.top._templateFrameId >= 2002 && Fai.top._templateFrameId <= 2004) {
                    jm('.productMallBuyPanel').css('display', 'block');
                }
                var videoTags = jm('video');
                videoTags.css('display', 'block');
            },
        });
    function productInfo() {
        if (deviceTypeIsMobi() && jm('#mallOptionAndNumContainer').length > 0) {
            if (Mobi.productNewDetailIScrollObj) return;
            Mobi.productNewDetailIScrollObj = new iScroll('mallOptionAndNumContainer', {
                onBeforeScrollStart(e) {
                    var target = e.target;
                    while (target.nodeType != 1) target = target.parentNode;
                    if (target.tagName != 'INPUT') e.preventDefault();
                },
            });
        }
        if (!isSetContentHeight) {
            contentHeight();
        }

        return false;
    }

    function setGroupBuyRestrict() {
        if (isPtLimitBuy) {
            if (Mobi.isPtBtn) {
                //拼团限购有效，并且当前是点击了拼团按钮。处理拼团限购相关
                jm('#limitAmountDiv').hide();
                jm('.J_productNumberContent_simple').hide();

                jm('#limitAmountDiv_pt').show();
                jm('.J_productNumberContent_pt').show();
            } else {
                jm('#limitAmountDiv_pt').hide();
                jm('.J_productNumberContent_pt').hide();

                jm('#limitAmountDiv').show();
                jm('.J_productNumberContent_simple').show();
            }
        }
    }

    function setIntegralMallRestrict() {
        if (isImLimitBuy) {
            if (Mobi.isImBtn) {
                //积分商城限购有效，并且当前是点击了积分商城按钮。处理积分商城限购相关
                jm('#limitAmountDiv').hide();
                jm('.J_productNumberContent_simple').hide();

                jm('#limitAmountDiv_im').show();
                jm('.J_productNumberContent_im').show();
            } else {
                jm('#limitAmountDiv_im').hide();
                jm('.J_productNumberContent_im').hide();

                jm('#limitAmountDiv').show();
                jm('.J_productNumberContent_simple').show();
            }
        }
    }

    function setEarnestSaleRestrict() {
        if (Mobi.isEsBtn) {
            jm('.productName').hide();
        }
    }

    jm('#productInfoSize').bind('click', function () {
        var $productMallBuyPanel = jm('.productMallBuyPanel');
        if (!isHotelProduct) {
            if (btnHtml == null || btnHtml == '') return;
            $productMallBuyPanel.html(btnHtml);
        } else {
            if (pmbpHtml == null || pmbpHtml == '') return;
            $productMallBuyPanel.html(pmbpHtml);
            mallBuyType = 1;
        }

        var _templateFrameId = Fai.top._templateFrameId;
        var $productAddMall = jm('.productMallBuyPanel .productAddMall');
        var isNewColorTheme = _templateFrameId >= 1015 && _templateFrameId < 2000;
        if (_templateFrameId < 2000 || _templateFrameId >= 3000) {
            //margin
            $productAddMall.css('margin-left', '0.45rem');
            if (_templateFrameId == 1014) {
                $productAddMall.css('margin-left', '0.85rem');
            }
        }
        if (_templateFrameId >= 2002 && _templateFrameId <= 2004) {
            $productMallBuyPanel.css('display', 'flex');
        }
        //剔除内联普通主题1500-1999的margin by jser 2017-7-10
        if (isNewColorTheme) {
            $productAddMall.css({
                'margin-left': '',
                'margin-top': '',
            });
            //fix 复制html，把style属性也复制过来了，导致多了一个margin-left，影响1500~1999主题的排版, 针对1500~1999主题做特殊处理
            //by jser 2017-8-9
            $productMallBuyPanel.find('.mallThemeAddCartBtn').css('margin-left', '');
        }
        jm('.productMallBuyPanel .productAddMall')
            .css('color', jm('.productNewDetailPanel .productAddMall').css('color'))
            .css('border-color', jm('.productNewDetailPanel .productAddMall').css('border-left-color'));
        jm('.productMallBuyPanel .productImmediatelyBug')
            .css('background-color', jm('.productNewDetailPanel .productImmediatelyBug').css('background-color'))
            .css('border-color', jm('.productNewDetailPanel .productImmediatelyBug').css('border-left-color'));

        if (!isNewColorTheme) {
            jm('.productMallBuyPanel div').css('margin-top', '0'); //margin
        }

        jm('.productMallOptionPanel .productMallOptionContentPanel')
            .css('overflow', 'hidden')
            .addClass('mallThemeProductContentPanel'); //修复火狐这里出现滚动的导致按钮位置不够而换行

        jm('.productMallBuyPanel .J_productAddMall').bind('click', function () {
            mallBuy(productId, 0);
        });
        jm('.productMallBuyPanel .J_productImmediatelyBug')
            .unbind('click')
            .bind('click', function () {
                if (!isSecKill) {
                    mallBuy(productId, 1);
                } else {
                    mallBuy(productId, 1, null, null, null, null, {
                        isSecKill,
                        secKillId,
                    });
                }
            });
        jm('.productMallBuyPanel .J_groupBuyBtn')
            .unbind('click')
            .bind('click', function () {
                mallBuy(productId, 1, null, null, null, null, {
                    isGroupBuy,
                    groupBuyId,
                    groupBuyDetailId,
                });
            });
        jm('.productMallBuyPanel .J_cpButton')
            .unbind('click')
            .bind('click', function () {
                logDog(200535, 0);
                cutPrice(cutPriceId, productId);
            });
        jm('.productMallBuyPanel .serviceBtn')
            .off('click.pp')
            .on('click.pp', function () {
                createMallServicePanel();
            });
        jm('.productMallBuyPanel .J_integralMallBtn')
            .unbind('click')
            .bind('click', function () {
                mallBuy(productId, 1, null, null, null, null, {
                    isIntegralMall,
                    integralMallId,
                });
            });
        jm('.productMallBuyPanel .J_earnestSaleBtn')
            .unbind('click')
            .bind('click', function () {
                mallBuy(productId, 1, null, null, null, null, {
                    isEarnestSale,
                    earnestSaleId,
                });
            });
    });

    var isSetContentHeight = false;
    function contentHeight() {
        isSetContentHeight = true;
    }

    //-----------------
    var mallBuyType;
    jm('#productNewDetailPanel' + moduleId + ' .J_productAddMall').bind('click', function () {
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 0;
        Mobi.isPtBtn = false;
        Mobi.isImBtn = false;
        Mobi.isMarketingBtn = false;
        productInfoWebPage();
    });

    jm('.J_pdJoinGroupBuyBtn').bind('click', function () {
        if (Fai.top.sessionMemberId == 0) {
            Fai.top.location.href =
                preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?returnUrl=' + jm.encodeUrl(Fai.top.location.href);
        }
        groupBuyDetailId = jm(this).attr('_groupBuyDetailId');
        var block = jm(this).attr('_oldMemberBlock');

        if (block === 'true') {
            var html = '';
            html +=
                "<div style='width: 11.9rem; height: 10.476rem; background: rgba(255,255,255,1); border-radius: .357rem; margin: 0 auto; position: relative; top: -3rem; display: flex; flex-direction: column; align-items: center; padding: 1.05rem; box-sizing: border-box;'>";
            html += "<div style='width: 8.12rem; height: 4.69rem; overflow: hidden; margin-bottom: .84rem;'>";
            html +=
                "<img src='" +
                Fai.top._resImageRoot +
                "/image/gbNewMemOnly.png' style='width: 8.12rem; height: 4.69rem;'>";
            html += '</div>';
            html +=
                "<div style='font-size: .62rem; color:rgba(0,0,0,1); margin-bottom: .857rem;'>仅限新客参团，老客可以开团</div>";
            html +=
                "<div class='J-prompt-callback' style='width: 5.714rem; height: 1.43rem; background: rgba(242,48,48,1); border-radius: .1429rem; color: #fff; margin: 0 auto; font-size: .62rem; display: flex; align-items: center; justify-content: center; cursor: pointer;'>去开新团</div>";
            html += '</div>';
            html +=
                "<div class='J-prompt-cancel' style='display: inline-block; width: 1.67rem; height: 1.67rem; z-index: 9999; margin: 0 auto; cursor: pointer;'>";
            html += "<img src='" + Fai.top._resImageRoot + "/image/hdCouponClose.png'>";
            html += '</div>';

            customPopup({
                content: html,
                callback() {
                    logDog(200890, 4);
                    if (isGroupBuy) {
                        jm('#mallPrice').html(groupPriceStr);
                    }
                    jm('.productMallBuyPanel').html(pmbpHtml);
                    mallBuyType = 2;
                    groupBuyDetailId = 0;
                    Mobi.isPtBtn = true;
                    Mobi.isImBtn = false;
                    Mobi.isMarketingBtn = true;
                    productInfoWebPage();
                },
            });
            return;
        }

        mallBuyType = 2;
        productInfoWebPage();
        setGroupBuyRestrict();
        jm('#mallPrice').html(groupPriceStr); //参团按钮，肯定显示拼团价啦

        Mobi.isPtBtn = true;
        Mobi.isImBtn = false;
        Mobi.isMarketingBtn = true;
    });

    jm('.productMallBuyPanel').delegate('.productMallBuy', 'click', function () {
        if (Mobi._isCommunityGroup) {
            Mobi.mallBuy(productId, 0, null, null, null, null, {
                cb: (optionList, count) => {
                    Mobi._communityPdDetailVm && Mobi._communityPdDetailVm.confirmPd2Cart(productId, optionList, count);
                },
                hideAddCartTips: true,
                fromCommunityGroup: true,
            });
            return;
        }
        if (mallBuyType == 1) {
            if (isSecKill) {
                mallBuy(productId, 1, null, null, null, null, {
                    isSecKill,
                    secKillId,
                });
            } else {
                mallBuy(productId, mallBuyType);
            }
        } else if (mallBuyType == 2) {
            mallBuy(productId, 1, null, null, null, null, {
                isGroupBuy,
                groupBuyId,
                groupBuyDetailId,
            });
        } else if (mallBuyType == 3) {
            cutPrice(cutPriceId, productId);
        } else if (mallBuyType == 4) {
            if (Fai.top.sessionMemberId == 0) {
                Fai.top.location.href =
                    preprocessUrl({ path: '', oldPath: '' }) +
                    'login.jsp?returnUrl=' +
                    jm.encodeUrl(Fai.top.location.href);
            }
            mallBuy(productId, 1, null, null, null, null, {
                isIntegralMall,
                integralMallId,
            });
        } else if (mallBuyType == 5) {
            mallBuy(productId, 1, null, null, null, null, {
                isEarnestSale,
                earnestSaleId,
            });
        } else {
            mallBuy(productId, mallBuyType, null, null, null, null, {
                cb: function () {
                    // 秒杀埋点
                    if (isSecKill) {
                        if (mallBuyType == 0) {
                            logMallFdp(
                                'mall_seckill_gwc',
                                {
                                    mall_free_bool_0: true, // 秒杀产品是否加入购物车
                                },
                                true
                            );
                        } else {
                            logMallFdp(
                                'mall_seckill_js',
                                {
                                    mall_free_bool_0: false, // 秒杀产品是否在购物车结算
                                },
                                true
                            );
                        }
                    }
                },
            });
        }
    });

    var oldMallPrice = jm('#mallPrice').html() || '';
    jm(productImmediatelyBug).bind('click', function () {
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 1;
        Mobi.isPtBtn = false;
        Mobi.isImBtn = false;
        Mobi.isEsBtn = false;
        Mobi.isMarketingBtn = false;
        productInfoWebPage();
        setGroupBuyRestrict();
        setIntegralMallRestrict();
        setEarnestSaleRestrict();
        resetOptionContent();
        if (isGroupBuy) {
            //拼团情况下，恢复原来价格显示，并触发购物选项改变价格
            jm('#mallPrice').html(oldMallPrice);
            jm(jm('#productMallOptionPanel .optionSelected')[0]).click().click();
        }
        if (isIntegralMall) {
            //积分商城情况下，恢复原来价格显示，并触发购物选项改变价格
            jm('#mallPrice').html(oldMallPrice);
            jm(jm('#productMallOptionPanel .optionSelected')[0]).click().click();
            jm('#integral').hide();
            jm('#itgName').hide();
            jm('#plus').hide();
        }
        if (isEarnestSale) {
            //定金预售情况下，恢复原来价格显示，并触发购物选项改变价格
            jm('#mallPrice').html(oldMallPrice);
            jm(jm('#productMallOptionPanel .optionSelected')[0]).click().click();
        }
    });
    jm(groupBuyBtn).bind('click', function () {
        if (Fai.top.sessionMemberId == 0) {
            Fai.top.location.href =
                preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?returnUrl=' + jm.encodeUrl(Fai.top.location.href);
        }
        var block = jm(this).find('._oldMemberBlock').length > 0;
        if (block) {
            var html = '';
            html +=
                "<div style='width: 11.9rem; height: 10.476rem; background: rgba(255,255,255,1); border-radius: .357rem; margin: 0 auto; position: relative; top: -3rem; display: flex; flex-direction: column; align-items: center; padding: 1.05rem; box-sizing: border-box;'>";
            html += "<div style='width: 8.12rem; height: 4.69rem; overflow: hidden; margin-bottom: .84rem;'>";
            html +=
                "<img src='" +
                Fai.top._resImageRoot +
                "/image/gbNewMemOnly.png' style='width: 8.12rem; height: 4.69rem;'>";
            html += '</div>';
            html +=
                "<div style='font-size: .62rem; color:rgba(0,0,0,1); margin-bottom: .857rem;'>仅限新客参团，老客可以开团</div>";
            html +=
                "<div class='J-prompt-callback' style='width: 5.714rem; height: 1.43rem; background: rgba(242,48,48,1); border-radius: .1429rem; color: #fff; margin: 0 auto; font-size: .62rem; display: flex; align-items: center; justify-content: center; cursor: pointer;'>去开新团</div>";
            html += '</div>';
            html +=
                "<div class='J-prompt-cancel' style='display: inline-block; width: 1.67rem; height: 1.67rem; z-index: 9999; margin: 0 auto; cursor: pointer;'>";
            html += "<img src='" + Fai.top._resImageRoot + "/image/hdCouponClose.png'>";
            html += '</div>';

            customPopup({
                content: html,
                callback() {
                    logDog(200890, 4);
                    if (isGroupBuy) {
                        jm('#mallPrice').html(groupPriceStr);
                    }
                    jm('.productMallBuyPanel').html(pmbpHtml);
                    mallBuyType = 2;
                    groupBuyDetailId = 0;
                    Mobi.isPtBtn = true;
                    Mobi.isImBtn = false;
                    Mobi.isMarketingBtn = true;
                    productInfoWebPage();
                },
            });
            return;
        }

        if (isGroupBuy) {
            jm('#mallPrice').html(groupPriceStr);
        }
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 2;
        groupBuyDetailId = initGroupBuyDetailId;
        Mobi.isPtBtn = true;
        Mobi.isImBtn = false;
        Mobi.isMarketingBtn = true;
        productInfoWebPage();
        setGroupBuyRestrict();
        resetOptionContent();
    });
    jm(cutPriceBtn).bind('click', function () {
        if (mallOptionsOpen) {
            if (isCutPrice) {
                jm('#mallPrice').html(cpPriceStr);
            }
            jm('.productMallBuyPanel').html(pmbpHtml);
            mallBuyType = 3;
            Mobi.isPtBtn = false;
            Mobi.isImBtn = false;
            Mobi.isMarketingBtn = false;
            productInfoWebPage();
        } else {
            cutPrice(cutPriceId, productId);
        }
    });
    jm(integralMallBtn).bind('click', function () {
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 4;
        Mobi.isImBtn = true;
        Mobi.isPtBtn = false;
        Mobi.isMarketingBtn = true;
        productInfoWebPage();
        setIntegralMallRestrict();
        resetOptionContent();
        if (isIntegralMall) {
            var optionlines = jm('.J-op-box').find('.J-op'),
                optionsSelect = jm('.J-op-box').find('.optionSelected');
            if (optionlines.length == 0 || (optionlines.length > 0 && optionsSelect.length < optionlines.length)) {
                jm('#integral').html(imMinPrice.minIntegral);
                jm('#mallPrice').html(parseFloat(imMinPrice.minPrice).toFixed(2));
            }

            if (integtalMallType == 1) {
                jm('#choiceCurrencyVal').hide();
                jm('#mallPrice').hide();
            }

            jm('#integral').show();
            jm('#itgName').show();
            jm('#plus').show();
            jm(jm('#productMallOptionPanel .optionSelected')[0]).click().click(); //积分商城情况下，恢复积分商城的最小价格显示，并触发购物选项改变价格
        }
    });

    jm(earnestSaleBtn).bind('click', function () {
        jm('.productMallBuyPanel').html(pmbpHtml);
        mallBuyType = 5;
        Mobi.isImBtn = false;
        Mobi.isPtBtn = false;
        Mobi.isEsBtn = true;
        Mobi.isMarketingBtn = true;
        productInfoWebPage();
        setEarnestSaleRestrict();
    });

    //Mobi.prodcutUserComment();

    /*  share start */
    var shareListForPromoWhenCloseShare = JSON.parse(JSON.stringify(Fai.top._shareDataProdectDetail));
    var prepareList = '[{"url":"?","icon":"Weixin"}]';
    shareListForPromoWhenCloseShare.shareLinkList = prepareList;
    var shareOption;
    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000) {
        shareOption = {
            triggerId: 'mallThemeProductDetailShare',
            pageBg: true,
            pageHtml: sharePage(Fai.top._shareDataProdectDetail),
            direction: 'bottom',
            callback() {
                initProductSwipe('shareListShowSwipe');
            },
            closeWebPage: '#shareListPanelBg',
        };
        initWebPage(shareOption);

        shareOption = {
            triggerId: 'promoShareIconForNotOpenShare',
            pageBg: true,
            pageHtml: sharePage(shareListForPromoWhenCloseShare),
            direction: 'bottom',
            callback() {
                initProductSwipe('shareListShowSwipe');
            },
            closeWebPage: '#shareListPanelBg',
        };
        initWebPage(shareOption);
    } else {
        shareOption = {
            triggerId: 'productDetailShare',
            pageBg: true,
            pageHtml: sharePage(Fai.top._shareDataProdectDetail),
            direction: 'bottom',
            callback() {
                initProductSwipe('shareListShowSwipe');
            },
            closeWebPage: '#shareListPanelBg',
        };
        initWebPage(shareOption);

        shareOption = {
            triggerId: 'promoShareIconForNotOpenShare',
            pageBg: true,
            pageHtml: sharePage(shareListForPromoWhenCloseShare),
            direction: 'bottom',
            callback() {
                initProductSwipe('shareListShowSwipe');
            },
            closeWebPage: '#shareListPanelBg',
        };
        initWebPage(shareOption);
    }
    /*  share end */
    //优惠券领取弹窗start
    var couponPanelOption = {
        triggerId: 'showCouponList',
        pageBg: true,
        pageHtml: couponPage(Fai.top._couponInfo),
        direction: 'bottom',
        closeWebPage: '#contentBottomPanel',
        callbackAlways: true,
        callback() {
            jm('#couponListBottomPanel')
                .unbind('click mousedown touchstart')
                .bind('click mousedown touchstart', function (e) {
                    e.stopPropagation();
                });
            jm('#contentBottomPanel').show();
            jm('.toUseCoupon')
                .unbind('click')
                .bind('click', function (e) {
                    var couponId = jm(this).parents('.couponItem').attr('couponid');
                    Fai.top.location.href = preprocessUrl({ path: '', oldPath: '' }) + 'pr.jsp?couponId=' + couponId;
                    e.stopPropagation();
                });
            logDog(200474, 4);
            for (let i = 0; i < Fai.top._couponInfo.length; i++) {
                logDog(200474, 5);
            }
        },
    };
    initWebPage(couponPanelOption);
    //优惠券领取弹窗end
    /* show and hide placeholder */
    hidePlaceholder();

    //重新渲染购物选项列表
    function resetOptionContent() {
        var opAmountInfo = {};
        var showOptionList = [];
        if (Mobi.isMarketingBtn) {
            opAmountInfo = mkt_optionsAmountInfo;
            showOptionList = mkt_showOptionList;
        } else {
            opAmountInfo = pd_optionsAmountInfo;
            showOptionList = pd_showOptionList;
        }

        if (showOptionList.length <= 0) {
            return;
        }

        var oldOptionsStr = opAmountInfo.t || '';
        var oldOptionsArr = oldOptionsStr.split('_');
        var optionsAmountList = opAmountInfo.d || [];

        var index = 0;

        for (var i = 0; i < showOptionList.length; i++) {
            var showOption = showOptionList[i];
            var tmpShowItems = showOption.items || [];
            var tmpOptionFieldKey = showOption.fieldKey || '';

            for (var j = 0; j < oldOptionsArr.length; j++) {
                if (tmpOptionFieldKey == oldOptionsArr[j]) {
                    index = j;
                }
            }
            if (showOption.type == 4) {
                //独立
                tmpOptionFieldKey = 'opIndept' + tmpOptionFieldKey;
            }
            // 打印出列表
            if (tmpShowItems.length > 0 && !openPriceCalendar) {
                jm.each(tmpShowItems, function (tmpIndex, tmpItem) {
                    var tmpItemId = tmpItem.i;
                    var flag = false;

                    jm.each(optionsAmountList, function (tmpIndex2, data) {
                        var t2 = data.t2;
                        var productFlag = data.flag === null ? 1 : data.flag;
                        var t2Arr = t2.split('_');
                        if (productFlag == 1 && index < t2Arr.length && parseInt(t2Arr[index]) == tmpItemId) {
                            flag = true;
                            return false;
                        }
                    });
                    if (flag) {
                        jm(".g_mallLabel[for='" + tmpOptionFieldKey + tmpItemId + "']").show();
                    } else {
                        jm(".g_mallLabel[for='" + tmpOptionFieldKey + tmpItemId + "']").hide();
                    }
                });
                index++;
            }
        }
    }
}

export function initAjaxTab(options) {
    if (!options) return;
    var pid = options.pid,
        moduleId = options.moduleId,
        tabUl = document.querySelector('#productDetailTabPanel' + moduleId + ' .tabUl'),
        tabBody = document.querySelector('#productDetailTabPanel' + moduleId + ' .tabBody'),
        productNewDetailPanel = document.getElementById('productNewDetailPanel' + moduleId),
        themeV3 = options.themeV3 || false,
        isUseSwipe = options.isUseSwipe;
    if (typeof isUseSwipe === 'undefined') {
        isUseSwipe = true;
    }
    let getTabContent = function (target) {
        if (!target) return;

        var tabLiId = parseInt(target.getAttribute('data')),
            currentTabPanel = document.getElementById('tabPanel' + tabLiId),
            currentTabLi = document.getElementById('tabLi' + tabLiId),
            tabLiActive = productNewDetailPanel.querySelector('.tabLi.tabActive'),
            tabPanelActive = productNewDetailPanel.querySelector('.tabPanel.tabPanelAtive');
        if (!currentTabPanel) return;
        if (themeV3) {
            jm(tabLiActive).removeClass('tabActive g_main_color_v3 g_main_bdColor_v3');
            jm(currentTabLi).addClass('tabActive g_main_color_v3 g_main_bdColor_v3');
        } else {
            jm(tabLiActive).removeClass('tabActive');
            jm(currentTabLi).addClass('tabActive');
        }

        jm(tabPanelActive).removeClass('tabPanelAtive');
        jm(currentTabPanel).addClass('tabPanelAtive');
        setTimeout(function () {
            if (!Fai.top._openThemeV3) {
                Mobi.productTabUlScroll();
            }
        }, 0);
        var r = jm('html').css('font-size');
        r = r.substring(0, r.length - 2);

        if (
            // 只有tabLiId1,2且管理态的时候是直出html，其余情况都走ajax获取
            // --bug=1077582 --user=陈奖 【产品详情】主题1.0新版，点击查看参数tab报错 https://www.tapd.cn/42954297/s/1373567
            // --bug=1081977 --user=陈奖 【产品详情页】切换到产品评论栏，显示空白 https://www.tapd.cn/42954297/s/1373414
            // --bug=1083009 --user=陈奖 【产品详情】访客态详情中的图片点击没有打开大图 https://www.tapd.cn/42954297/s/1399374
            currentTabPanel.getAttribute('isLoading') === null &&
            (![1, 2].includes(tabLiId) || !(VITE_APP_MODE !== 'visitor'))
        ) {
            var tabPanelLoading = "<div id='tabPanelLoading' class='tabPanelLoading'></div>";
            jm(tabBody).append(tabPanelLoading);
            var ajax_data =
                'pid=' +
                pid +
                '&type=' +
                tabLiId +
                '&moduleId=' +
                moduleId +
                '&rem=' +
                r +
                '&loadingPath=' +
                Fai.top._loadingPath +
                '&columnProductId=' +
                pid;
            jm.ajax({
                type: 'post',
                url:
                    addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
                    '/product_h.jsp?cmd=getWafNotCk_getProductDetailInfo',
                data: ajax_data,
                error() {
                    ing(LS.js_systemError);
                    if (tabLiId == 3) {
                        logMonitor(5932, true);
                    }
                },
                success(result) {
                    var data = jm.parseJSON(result);
                    if (!data.html) {
                        jm('#tabPanelLoading').remove();
                        data.html = '';
                    }
                    if (tabLiId == 3) {
                        currentTabPanel.innerHTML = data.html;
                        initCommentUserTab(3);
                        setCommImgBindShow();
                        setTimeout(function () {
                            bindImgLazyLoad('tabPanel' + tabLiId);
                            initPdMsgDelEvent(pid);
                            // initPictureSlidesV4ByDom('.s-img-bd img');
                        }, 100);
                        if (!data.success) {
                            logMonitor(5932, true);
                        }
                    } else if (tabLiId === 1) {
                        data.html =
                            "<div class='richContent productDetailInfoPanel g_defaultColor'>" + data.html + '</div>';
                        if (!Fai.top._productRecommentData) {
                            currentTabPanel.innerHTML = data.html;
                            jm('#tabPanelLoading').remove();
                            switchJump();
                            // 产品详情 ue 接入新幻灯片 @qjie
                            if (isUseSwipe) {
                                updateProductDetailSwipe(
                                    '#productDetailTabPanel' + moduleId + ' #tabPanel' + tabLiId + ' img'
                                );
                            }
                            setTimeout(function () {
                                bindImgLazyLoad('tabPanel' + tabLiId);
                                window.bizShared.renderSingleMap(`#module${moduleId} #tabPanel${tabLiId}`);
                            }, 100);
                            return;
                        }
                        currentTabPanel.innerHTML = data.html + Fai.top._productRecommentData;
                        productCrossedSlideSec('mProductList' + moduleId, 1, 1, 'paramWrap');
                        productCrossedSlideSecSwipe('productSlideList' + moduleId, 'productSlide' + moduleId);
                        switchJump();
                        // 产品详情 ue 接入新幻灯片 @qjie
                        if (isUseSwipe) {
                            updateProductDetailSwipe(
                                '#productDetailTabPanel' + moduleId + ' #tabPanel' + tabLiId + ' img'
                            );
                        }
                        setTimeout(function () {
                            bindImgLazyLoad('tabPanel' + tabLiId);
                            window.bizShared.renderSingleMap(`#module${moduleId} #tabPanel${tabLiId}`);
                        }, 100);

                        if (window.isGroupBuy) {
                            showGroupBuyMore(pid, 'product');
                        }
                    } else if (tabLiId === 2) {
                        currentTabPanel.innerHTML = data.html;
                        setTimeout(function () {
                            bindImgLazyLoad('tabPanel' + tabLiId);
                        }, 100);
                    } else {
                        data.html =
                            "<div class='richContent productDetailInfoPanel g_defaultColor'>" + data.html + '</div>';
                        currentTabPanel.innerHTML = data.html;
                        switchJump();
                        // 产品详情 ue 接入新幻灯片 @qjie
                        updateProductDetailSwipe('#productDetailTabPanel' + moduleId + ' #tabPanel' + tabLiId + ' img');
                        // initModulePhotoSwipe('productDetailTabPanel'+moduleId + " #tabPanel" + tabLiId);
                        setTimeout(function () {
                            bindImgLazyLoad('tabPanel' + tabLiId);
                            window.bizShared.renderSingleMap(`#module${moduleId} #tabPanel${tabLiId}`);
                        }, 100);
                    }
                    currentTabPanel.setAttribute('isLoading', 'true');
                    jm('#tabPanelLoading').remove();
                },
            });
        }

        // 修复管理态第一个tab不能打开幻灯片
        if (VITE_APP_MODE !== 'visitor' && currentTabPanel.innerHTML != '') {
            // initModulePhotoSwipe('productDetailTabPanel'+moduleId + " #tabPanel" + tabLiId);
            // 产品详情 ue 接入新幻灯片 @qjie
            updateProductDetailSwipe('#productDetailTabPanel' + moduleId + ' #tabPanel' + tabLiId + ' img');
        }

        // 修复推荐产品没有出现的bug
        // 由于产品详情tab静态化，因此不放在inc，以免没有更新到。
        // 当当前tab是产品详情，且开启推荐产品，且插入过推荐产品的html，则初始化推荐产品。@ken
        if (
            tabLiId == 1 &&
            typeof Fai.top._productRecommentData == 'string' &&
            Fai.top._productRecommentData != '' &&
            jm('#productSlide' + moduleId).length < 1
        ) {
            jm(currentTabPanel).append(Fai.top._productRecommentData);
            productCrossedSlideSec('mProductList' + moduleId, 1, 1, 'paramWrap');
            productCrossedSlideSecSwipe('productSlideList' + moduleId, 'productSlide' + moduleId);
            bindImgLazyLoad('productSlideList' + moduleId);
        }
    };
    //页面高度不够时，底部按钮无法显示，这里初始化时使面板高度为屏幕高度
    if (!Fai.top._openThemeV3) {
        jm(productNewDetailPanel).css(
            'min-height',
            jm(VITE_APP_MODE !== 'visitor' ? '#g_web' : window).height() + 'px'
        );
    }
    if (!tabUl.querySelector('.tabActive')) return;
    jm(tabUl).bind('click', function (e) {
        var target = e.target;
        if (target) {
            getTabContent(target);
            return false;
        }
    });
    getTabContent(tabUl.querySelector('.tabActive'));
    initCommentUserTab(parseInt(tabUl.querySelector('.tabActive').getAttribute('data')));
}

function initCommentUserTab(tabLiId) {
    if (tabLiId !== 3) return;
    var urlStr =
        addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
        '/productComment_h.jsp?cmd=addWafCk_submitPC&newComment=true';
    var commentOption = {
        triggerId: 'userCommentId',
        panelId: 'userCommentPanel',
        callback() {
            postUserComment(urlStr, 0);
        },
        closeWebPage: '#userCommentPanel .userCommentGoBack',
    };
    initWebPage(commentOption);

    //在点击我要评论时，添加验证码src，防止微博抓取到验证码图片
    jm('#userCommentId').one('click', function () {
        jm('#loginCaptchaImg').attr({ src: 'validateCode.jsp?v=610' });
    });
}

function showGroupBuyMore(currentPid, detailName) {
    var groupBuyHtml = '';
    jm.ajax({
        url:
            addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
            '/groupBuy_h.jsp?cmd=getWafNotCk_getGroupBuyProductList',
        data: 'currentPid=' + currentPid,
        type: 'POST',
        dataType: 'json',
        success(result) {
            if (!result.success) {
                Fai.img('系统错误');
                return;
            }
            /*初始化*/
            var productInfoList = result.productInfo;
            var choiceCurrencyVal = result.choiceCurrencyVal;
            var listSize = productInfoList.length;
            var groupBuyDetailClass = '';
            var logDog = 1;
            if (listSize == 0) {
                return;
            }
            // 产品详情页
            if (detailName == 'product') {
                var mProductList = jm('.mProductList');
                mProductList.hide();
                if (mProductList.length == 0) {
                    groupBuyHtml += "<div class='mallThemeSeparator g_breakline'></div>";
                }
            } else {
                groupBuyDetailClass = 'borderTop';
                logDog = 2;
            }
            /*拼接结构*/
            groupBuyHtml +=
                "<div class='f_groupBuyMore " +
                groupBuyDetailClass +
                "'>" +
                "<div class='title'>" +
                "<span class='titleLine'></span>" +
                "<span class='titleName'>TA们正在拼</span>" +
                "<span class='titleLine'></span>" +
                '</div>';
            if (listSize == 1) {
                var productInfo = productInfoList[0];
                var id = productInfo.id,
                    name = productInfo.name,
                    picPath = productInfo.picPath,
                    groupBuyInfo = productInfo.groupBuyInfo;

                var preprocessUrl = preprocessUrl({ path: '', oldPath: '' }) + 'pd.jsp?id=' + id;
                if (productInfo.productVideoImg && productInfo.productVideoImg != '') {
                    picPath = productInfo.productVideoImg;
                }
                groupBuyHtml +=
                    "<div class='single'>" +
                    "<div class='leftPic' style='background:url(" +
                    picPath +
                    ") no-repeat;background-size: 100% 100%;-moz-background-size: 100% 100%;'>" +
                    "<div class='marketingTip g_bgColor g_main_bgColor_v3'>" +
                    groupBuyInfo.groupNum +
                    '人团</div>' +
                    '</div>' +
                    "<div class='rightInfo'>" +
                    "<div class='pdName'>" +
                    name +
                    '</div>' +
                    "<div class='groupPrice g_color g_main_color_v3'>" +
                    choiceCurrencyVal +
                    groupBuyInfo.groupPrice +
                    '</div>' +
                    "<a href='" +
                    preprocessUrl +
                    "' onclick='Mobi.logDog(200892, " +
                    logDog +
                    ");' class='jumpToUrl g_bgColor g_main_bgColor_v3'>立即拼</a>" +
                    '</div>' +
                    "<a href='" +
                    preprocessUrl +
                    "' onclick='Mobi.logDog(200892, " +
                    logDog +
                    ");' class='overHidden'></a>" +
                    '</div>';
            } else {
                groupBuyHtml += "<div class='multiple'>";
                for (var i = 0; i < listSize; i++) {
                    let productInfo = productInfoList[i];
                    let name = productInfo.name,
                        picPath = productInfo.picPath,
                        groupBuyInfo = productInfo.groupBuyInfo,
                        id = productInfo.id,
                        preprocessUrl = preprocessUrl({ path: '', oldPath: '' }) + 'pd.jsp?id=' + id;
                    if (productInfo.productVideoImg && productInfo.productVideoImg != '') {
                        picPath = productInfo.productVideoImg;
                    }
                    var multipleRightItem = '';
                    if (i % 2 != 0) {
                        multipleRightItem = 'multipleRightItem';
                    }
                    groupBuyHtml +=
                        "<div class='multipleItem " +
                        multipleRightItem +
                        "'>" +
                        "<div class='topPic' style='background:url(" +
                        picPath +
                        ") no-repeat;background-size: 100% 100%;-moz-background-size: 100% 100%;'>" +
                        "<div class='marketingTip g_bgColor g_main_bgColor_v3'>" +
                        groupBuyInfo.groupNum +
                        '人团</div>' +
                        '</div>' +
                        "<div class='buttomInfo'>" +
                        "<div class='pdName'>" +
                        name +
                        '</div>' +
                        "<div class='groupPrice g_color g_main_color_v3'>" +
                        choiceCurrencyVal +
                        groupBuyInfo.groupPrice +
                        '</div>' +
                        "<a href='" +
                        preprocessUrl +
                        "' onclick='Mobi.logDog(200892, " +
                        logDog +
                        ");' class='jumpToUrl g_bgColor g_main_bgColor_v3'>立即拼</a>" +
                        '</div>' +
                        "<a href='" +
                        preprocessUrl +
                        "' onclick='Mobi.logDog(200892, " +
                        logDog +
                        ");' class='overHidden'></a>" +
                        '</div>';
                }
                groupBuyHtml += '</div>';
            }

            groupBuyHtml += '</div>';

            // 产品详情页
            if (detailName == 'product') {
                var currentTabPanel = document.getElementById('mallGroupBuyMore');
                // 用来判断是否需要添加“TA们正在拼”模块
                var currentTabPanelChild = document.getElementById('mallGroupBuyMore').childNodes;
                var isAddGroupBuy = true;
                // eslint-disable-next-line no-redeclare
                for (var i = 0; currentTabPanelChild.length > i; i++) {
                    if (currentTabPanelChild[i].className.indexOf('f_groupBuyMore') !== -1) {
                        isAddGroupBuy = false;
                        break;
                    }
                }
                if (isAddGroupBuy) {
                    currentTabPanel.innerHTML += groupBuyHtml;
                }
                setTimeout(function () {
                    bindImgLazyLoad('tabPanel1');
                }, 100);
            } else {
                jm('.fk-groupBuyDetail').append(groupBuyHtml);
            }
        },
    });
}

export function couponPage(couponInfo) {
    if (couponInfo == null) return '';
    var couponItem = [];
    var couponHtml = '';
    var closeIcon = '';
    var lineHeight = 'max-height: 10rem;';
    for (let i = 0; i < couponInfo.length; i++) {
        var coupon = couponInfo[i];
        var invalidCls = (!coupon.autoInc && coupon.couponLeaveAmount == 0) || coupon.allUsed ? 'invalid' : '';
        couponItem.push(
            `<div class ='couponPanelItem ${invalidCls}' couponid=${couponInfo[i].id} bgid='0' received="${coupon.isRecieve}" onclick='Mobi.clickCoupon(this, 1);' >`
        );
        var couponBgClass = ' g_bgColor g_main_bgColor_v3';
        var mainColorCls = ' g_mainColor g_main_color_v3';
        var mainBdColorCls = ' g_bdColor g_main_bdColor_v3';
        var receiveBtnCls = coupon.isRecieve ? mainBdColorCls + mainColorCls + ' use' : couponBgClass + ' get';
        couponItem.push(
            `<div class="couponBg ${couponBgClass}"></div><div class="dot dot1"></div><div class="dot dot2"></div>`
        );
        couponItem.push(
            `<div class="leftWrap"><div class="priceSign ${mainColorCls}">${Fai.top.choiceCurrencyVal}</div><div class="couponPrice ${mainColorCls}">${coupon.savePrice}</div><div class="couponCondition">${coupon.useCondition}</div></div>`
        );
        couponItem.push(
            `<div class="middleWrap"><div class="couponName">${coupon.couponName}</div><div class="validity">${coupon.validity}</div></div>`
        );
        couponItem.push(
            `<div class="receiveCoupon ${receiveBtnCls}" >${coupon.isRecieve ? '去使用' : '立即领取'}</div>`
        );
        couponItem.push(
            `<div class="invalidIcon"><div class="txt">${
                !coupon.autoInc && coupon.couponLeaveAmount == 0 ? '已领完' : '已使用'
            }</div></div>`
        );
        couponItem.push('</div>');
    }
    if (Fai.top._openThemeV3) {
        closeIcon =
            "<div id='closeCouponListBottomPanel' class='productDetailClose icon-Off g_iconMiddle icon-defaultColor'></div>";
        lineHeight = 'max-height: 19.2rem;';
    }
    couponHtml =
        "<div style='display:none; width:100%;' id ='contentBottomPanel'>" +
        "<div class='couponListBottomPanel' id ='couponListBottomPanel'>" +
        "<div class='couponListTitle'>优惠券</div>" +
        "<div id='slideCouponPanel' style='" +
        lineHeight +
        "overflow: auto;'>" +
        "<div class='couponListContainer'>" +
        couponItem.join('') +
        '</div>' +
        '</div>' +
        closeIcon +
        '</div>' +
        '</div>';
    return couponHtml;
}

export function initPdMsgDelEvent(pid, pageno = 1) {
    jm('.commentBoxPanel .commentBox .pdMsgDel')
        .unbind('click')
        .bind('click', (e) => {
            let { target } = e;
            if (target) {
                let id = parseInt(jm(target).attr('msg-id') || 0);
                if (id > 0) {
                    renderDelMsgConfirm({
                        title: LS.sureDelComment || '确定删除评论吗？',
                        cancelText: LS.cancel,
                        confirmText: LS.g_del,
                        confirm: async () => {
                            let result = await window.jzRequest.post('/ajax/product_h.jsp', {
                                data: {
                                    cmd: 'delWafCk_delCommentByMember',
                                    id,
                                },
                            });
                            if (result.data.success) {
                                let getCommonAjax = window._openThemeV3
                                    ? Mobi.getCommentAjaxPagenationV3
                                    : Mobi.getCommentAjaxPagenation;
                                getCommonAjax(pid, pageno);
                            } else {
                                mobiIng(result.data.msg);
                            }
                        },
                    });
                }
            }
        });
}
